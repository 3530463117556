import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Etapes from './components/Etapes/Etapes';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import CardGroup from './components/cardgroup/CardGroup';
import Espace from './components/espace/Espace';
import Header from './components/header/Header';
import Laptop from './components/ordinateur/Laptop';
import Tablette from './components/tablette/tablette';
import Services from './components/services/Service'; // Votre nouvelle page
import Projet from './components/projets/Projet';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Route pour la page d'accueil */}
        <Route
          path="/"
          element={
            <div>
              <Header />
              <Espace />
              <Laptop />
              <CardGroup />
              <Espace />
              <Tablette />
              <Espace />
              <Etapes />
              <Footer />
            </div>
          }
        />

        {/* Route pour la page Services */}
        <Route path="/services" element={<Services />} />

        {/* Route pour la page Services */}
        <Route path="/projet" element={<Projet />} />

        {/* Autres routes si nécessaire */}
        <Route path="*" element={<div>Page non trouvée</div>} />
      </Routes>
    </Router>
  );
}

export default App;
