import React, { useEffect } from 'react';
import "./Sticky.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'; // Exemple pour Calendly


function Sticky() {
    useEffect(() => {
        const handleScroll = () => {
            // Logique pour changer le style selon le scroll
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div id="sticky-button">
            <a href="https://www.linkedin.com/company/wecyde/?viewAsMember=true" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="mailto:contact@wecyde.com">
                <FontAwesomeIcon icon={faEnvelope} />  {/* Icône pour Email */}
            </a>
            <a href="tel:0033677970269">
                <FontAwesomeIcon icon={faPhone} />  {/* Icône pour Téléphone */}
            </a>
        </div>
    );
}

export default Sticky;
