import React, { useEffect, useState } from "react";
import "./Header.css";
import Button from "../button/Button";
import Sticky from "../Sticky/Sticky";

function Header() {
    const [currentText, setCurrentText] = useState("");
    const [isAppearing, setIsAppearing] = useState(true);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [letterIndex, setLetterIndex] = useState(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const texts = [
        " pour la création de sites web ",
        " pour la conception d'applications mobiles innovantes ",
        " qui donne vie à vos idées ! ",
        // Ajoutez d'autres textes ici
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            if (isAppearing) {
                if (letterIndex < texts[currentTextIndex].length) {
                    setCurrentText((prev) => prev + texts[currentTextIndex][letterIndex]);
                    setLetterIndex(letterIndex + 1);
                } else {
                    setIsAppearing(false);
                    setLetterIndex(letterIndex - 1);
                }
            } else {
                if (letterIndex >= 0) {
                    setCurrentText((prev) => prev.slice(0, -1));
                    setLetterIndex(letterIndex - 1);
                } else {
                    setIsAppearing(true);
                    setCurrentTextIndex((currentTextIndex + 1) % texts.length);
                    setLetterIndex(0);
                }
            }
        }, 70); // Ajustez la vitesse ici

        return () => clearInterval(interval);
    }, [currentTextIndex, isAppearing, letterIndex, texts]);

    return (
        <div className="header">
            <div className="information">
                <div className="wecyde">
                    <h1>WECYDE</h1>
                    <p>Votre partenaire {currentText} </p>
                </div>
                {/* <div className="Button">
                    <Button label="Parler à un conseiller" />
                </div> */}
            </div>
            <Sticky />
        </div>
    );
}

export default Header;
