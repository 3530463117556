import React from 'react';
import './Services.css';

const servicesData = [
  {
    category: 'Création de Sites Web',
    services: [
      {
        title: 'Pack Landing Page',
        description:
          'Une landing page percutante pour des campagnes marketing ou lancements de produits.',
        features: [
          'Design moderne et responsive.',
          'Formulaire de contact ou CTA.',
          'Hébergement inclus pour la première année.',
        ],
        price: '400 € - 600 € ou 50 € - 60 €/mois',
        payment: '3x150€',
        delay: '1 semaine (3 jours de design + 2 jours de développement)',
        example: 'Landing page pour un photographe freelance.',
      },
      {
        title: 'Pack Site Vitrine',
        description:
          'Site professionnel pour petites entreprises, auto-entrepreneurs.',
        features: [
          'Design personnalisé et responsive.',
          'Formulaire de contact et carte Google Maps.',
          'SEO basique pour une indexation initiale.',
        ],
        price: '700 € - 1 000 € ou 80 € - 90 €/mois',
        payment: '3x300€',
        delay: '1 à 2 semaines (3 jours de design + 3 à 5 jours de développement)',
        example: 'Site pour un coach sportif.',
      },
      {
        title: 'Pack Site Complet',
        description:
          'Site web détaillé pour PME ou entreprises nécessitant une présence en ligne avancée',
        features: [
          'Design personnalisé aligné sur l’image de marque. ',
          'Formulaire de contact avancé et carte Google Maps',
          'Support technique de 3 mois après lancement.',
        ],
        price: '1200€-1600€(paiementunique)ou150€-200€/mois (abonnement).',
        payment: '3x500€',
        delay: '2 à 3 semaines (3 jours de design + 7 à 10 jours de développement).',
        example: 'Site pour une entreprise de conseil.',
      },
      {
        title: 'Pack CRM pour Entreprises',
        description:
          'CRM personnalisé pour la gestion des clients, leads et interactions commerciales.',
        features: [
          'Base de données clients, tableau de bord, espace sécurisé. ',
          'Support technique de 6 mois pour l’assistance initiale.',
          'Support technique de 3 mois après lancement.',
        ],
        price: '2500€-4000€(paiementunique)ou300€-400€/mois',
        payment: '3x1000€. ',
        delay: '3 à 4 semaines (3 jours de design + 10 à 15 jours de développement) .',
        example: 'CRM pour une entreprise de services.',
      },
      // Ajoutez d'autres services ici...
    ],
  },
  {
    category: 'Création d\'Applications Mobiles',
    services: [
      {
        title: 'Pack Application Landing MVP',
        description:
          'Application de base pour tester une idée rapidement.',
        features: [
          'Interface utilisateur simple, jusqu’à 5 écrans.',
          'Authentification par email.',
          'Notifications push.',
        ],
        price: '2800 € - 4200 € ou 280 € - 350 €/mois',
        payment: '3x1000€',
        delay: '2 semaines (3 jours de design + 5 à 7 jours de développement)',
        example: 'Application de gestion d’événements.',
      },
      {
        title: 'Pack Application Standard',
        description:
          'Application complète avec des fonctionnalités interactives pour PME.',
        features: [
          'Jusqu’à 10 écrans.',
          'Localisation GPS.',
          'Paiements in-app et notifications avancées.',
        ],
        price: '7000 € - 10000 € ou 500 € - 650 €/mois',
        payment: '3x2500€',
        delay: '2 à 3 semaines (3 jours de design + 10 à 12 jours de développement)',
        example: 'Application pour une entreprise de livraison.',
      },
      {
        title: 'Pack Application avec Espace Client et Tableau de Bord',
        description:
          'Application avancée avec espace client et tableau de bord analytique.',
        features: [
          'Jusqu’à 15 écrans, géolocalisation, notifications push, backend évolutif.',
        ],
        price: '10000€-14000€(paiementunique)ou800€-1000€/mois (abonnement).',
        payment: '3x4000€',
        delay: '4 à 5 semaines (3 jours de design + 15 à 20 jours de développement).',
        example: 'Application pour services à domicile.',
      },
      {
        title: 'Pack Application Avancée avec Chat et Gestion de Données',
        description:
          'Application complète avec chat intégré et gestion de données pour entreprises.',
        features: [
          'Jusqu’à 20 écrans, chat, suivi d\'interactions, localisation GPS avancée.',
        ],
        price: '15000€-20000€(paiementunique)ou1200€-1500€/mois (abonnement).',
        payment: '3x6500€.',
        delay: '5 à 6 semaines (3 jours de design + 20 à 25 jours de développement).',
        example: 'Application pour entreprise de logistique.',
      },
    ],
  },
];

const Services = () => {
  return (
    <div className="services-page">
      <h1>Nos Services</h1>
      {servicesData.map((category, index) => (
        <section key={index} className="services-category">
          <h2>{category.category}</h2>
          <div className="services-cards">
            {category.services.map((service, idx) => (
              <div key={idx} className="service-card">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <ul>
                  {service.features.map((feature, id) => (
                    <li key={id}>{feature}</li>
                  ))}
                </ul>
                <p>
                  <strong>Prix :</strong> {service.price}
                </p>
                <p>
                  <strong>Paiement :</strong> {service.payment}
                </p>
                <p>
                  <strong>Délai :</strong> {service.delay}
                </p>
                <p>
                  <strong>Exemple :</strong> {service.example}
                </p>
              </div>
            ))}
          </div>
        </section>
      ))}
    </div>
  );
};

export default Services;
