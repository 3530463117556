import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../../asset/logo.png';

const Navbar = () => {
  const location = useLocation(); // Utilisation pour identifier la route active
  const navigate = useNavigate(); // Utilisation pour la navigation
  const [activeLink, setActiveLink] = useState('');

  // Fonction pour gérer la navigation et le défilement
  const handleNavigateAndScroll = (path, id) => {
    if (path === '/') {
      // Si on navigue vers la page d'accueil
      navigate(path);
      setTimeout(() => {
        const section = document.querySelector(id); // Sélectionne l'élément avec l'ID 'contact'
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' }); // Défilement fluide vers cet élément
        }
      }, 100); // Attente pour que la redirection soit terminée
    } else {
      // Simple redirection vers une autre page
      navigate(path);
    }
  };

  // Définissez les liens de navigation
  const links = [
    { name: 'Accueil', href: '/' },
    { name: 'Services', href: '/services' },
    { name: 'Réalisations', href: '/projet'},
    { name: 'Contact', href: '#contact' } // Contact redirige vers la section 'contact'
  ];

  // Mettre à jour l'état actif en fonction de la navigation
  const handleSetActiveLink = (link) => {
    setActiveLink(link);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="Logo de la société Weycde" />
        </Link>
        <div className="navbar-links">
          {links.map((item, index) => (
            <a
              key={index}
              href="#"
              className={`navbar-item ${
                activeLink === item.name.toLowerCase() ? 'active' : ''
              }`}
              onClick={(e) => {
                e.preventDefault(); // Empêche le comportement par défaut des balises <a>
                setActiveLink(item.name.toLowerCase()); // Met à jour le lien actif
                item.name === 'Contact'
                  ? handleNavigateAndScroll('/', '#contact') // Redirection vers '/' et défilement vers '#contact'
                  : navigate(item.href); // Redirection normale pour les autres liens
              }}
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
