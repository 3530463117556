import React, { useEffect, useRef, useState } from 'react';
import "./Laptop.css";
import ordi from "../../asset/laptop.svg";

function Laptop() {
    const [isVisible, setIsVisible] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(true);
            }
        }, { threshold: 0.3 }); // Déclencher quand 30% de l'élément est visible

        observer.observe(containerRef.current);

        return () => observer.disconnect();
    }, []);

    const handleScrollToContact = () => {
        const contactSection = document.querySelector('#contact'); // Sélectionne l'élément avec l'ID 'contact'
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' }); // Défilement fluide vers l'élément
        }
    };

    return (
        <div id='services' className={`Container-ordi ${isVisible ? 'slide-in-left' : ''}`} ref={containerRef}>
            <div className="gauche-ordi">
                <a href=""> <img src={ordi} alt="image" /></a>
            </div>
            <div className="droite-ordi">
                <h3>Conseils personnalisés</h3>
                <p>Rencontrez-vous des difficultés dans vos projets numériques ? Notre service de conseil sur mesure peut vous aider à surmonter ces obstacles et à atteindre vos objectifs. Nous proposons des solutions personnalisées adaptées à chaque projet unique, pour répondre précisément à vos besoins.</p>
                {/* <button onClick={handleScrollToContact}>Prendre rendez-vous!</button> */}
            </div>
        </div>
    );
}

export default Laptop;
