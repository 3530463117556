import React from "react";
import "./Projet.css";
import pipepilot from "../../asset/pipepilot.png";
import pipepilotpro from "../../asset/pipepilot-pro.png";
import intervention from "../../asset/intervention.png";

const projets = [
    {
        name: "site web Pipepilot",
        image: pipepilot, // Remplacez par le lien vers une image ou capture d'écran
        url: "https://pipepilot.wecyde.com/",
    },
    {
        name: "CRM pipepilot-pro",
        image: pipepilotpro, // Remplacez par le lien vers une image ou capture d'écran
        url: "https://pipepilot-pro.wecyde.com/",
    },
    {
        name: "Intervention pipepilot ",
        image: intervention, // Remplacez par le lien vers une image ou capture d'écran
        url: "https://intervention.wecyde.com/",
    },
];

const Projet = () => {
    return (
        <div className="projets-page">
            <h1>Nos Réalisations</h1>
            <div className="projets-cards">
                {projets.map((projet, index) => (
                    <div key={index} className="projet-card">
                        <img src={projet.image} alt={projet.name} className="projet-image" />
                        <h2>{projet.name}</h2>
                        <a href={projet.url} target="_blank" rel="noopener noreferrer">
                            <button className="projet-button">Visiter</button>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Projet;
